import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import React, { SetStateAction } from "react"

interface Props {
  index: number
  title: string
  contentJeson: any
  onSelect: (selIndex: number | null) => void
  isSelectedItem: boolean
}

export default function CollapseItem(props: Props) {
  const { contentJeson, index, title, onSelect, isSelectedItem } = props

  const color = isSelectedItem ? "#2d589a" : "rgba(13, 23, 48, 0.87)"
  const cardStyle = {
    margin: "8px 0px",
    border: "none",
    boxShadow: isSelectedItem
      ? "0px 4px 8px 2px rgb(3 26 70 / 8%)"
      : "0px 4px 8px 2px rgb(3 26 70 / 8%)",
    borderRadius: 8,
  } as React.CSSProperties

  return (
    <div
      className="card"
      style={cardStyle}
      onClick={() => {
        if (isSelectedItem) {
          onSelect(null)
        }
        onSelect(index)
      }}
    >
      <a
        data-toggle="collapse"
        data-target={`#collapse_${index + ""}`}
        aria-expanded="true"
        aria-controls={`collapse_${index + ""}`}
      >
        <div
          data-toggle="collapse"
          data-target={`#collapse_${index}`}
          id={`heading_${index}`}
          className="card-header"
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottomWidth: 0,
          }}
        >
          <p style={{ margin: 0, color }}>{title}</p>
          {isSelectedItem ? (
            <i style={{ color }} className="fas fa-chevron-up"></i>
          ) : (
            <i style={{ color }} className="fas fa-chevron-down"></i>
          )}
        </div>
      </a>

      <div
        id={`collapse_${index}`}
        className="collapse"
        aria-labelledby={`heading_${index}`}
        data-parent="#accordionExample"
      >
        <div className="card-body">
          <div className="cardBody">
            {documentToReactComponents(contentJeson)}
          </div>
        </div>
      </div>
    </div>
  )
}
