import React, { useState } from "react"
import { useAccordionToggle } from "react-bootstrap"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import "../css"
// import "../css/bootstrap.ltr.min.css"
import "../scss/index.scss"
import locales from "../translate/locales"
import { graphql } from "gatsby"
import CollapseItem from "../components/CollapseItem"

export const query = graphql`
  query {
    allContentfulCoachingAcademyPage {
      edges {
        node {
          introTitleAr
          introTitleEn

          weSaidSection {
            titleAr
            titleEn
            theSayingAr
            theSayingEn
            theSayingJobAr
            theSayingJobEn

            contentAr {
              childMarkdownRemark {
                html
              }
            }

            contentEn {
              childMarkdownRemark {
                html
              }
            }
          }

          ourSponsorsSection {
            logo {
              file {
                url
              }
            }
          }

          introTopSectionTextEn {
            childMarkdownRemark {
              html
            }
          }

          introTopSectionTextAr {
            childMarkdownRemark {
              html
            }
          }

          introBottomSectionTextEn {
            childMarkdownRemark {
              html
            }
          }

          introBottomSectionTextAr {
            childMarkdownRemark {
              html
            }
          }

          askedQuestions {
            questionTitleAr
            questionTitleEn

            answerEnglish {
              json
            }

            answerArabic {
              json
            }
          }
        }
      }
    }
  }
`

const coachingAcademy = (props: any) => {
  const locale = props.pageContext.locale
  const texts = locales[locale as "en"]
  const [selectedIndex, setSelectedIndex] = useState<number>()

  const longText = (value: any) => {
    const result = value.childMarkdownRemark.html.split(/\<.*?\>/g)
    return result
  }

  const pageContent = props.data.allContentfulCoachingAcademyPage.edges[0].node
  const askedQuestions = pageContent.askedQuestions
  const [selectedItem, setSelectedItem] = useState<number | null>(null)

  const translate = texts.coachingAcademyPageContent

  return (
    <div>
      <Header lang={locale} />

      <div
        className="page-title bg_cover mt-80 pt-140 pb-140"
        style={{
          backgroundImage: `url(/images/trainers_ar-jpg-scaled.jpg)`,
          height: 500,
          backgroundSize: "contain",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="page-title-item text-center"></div>
            </div>
          </div>
        </div>
      </div>

      <section className="access-area pt-120 pb-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="access-content">
                <div className="access-mission">
                  <h3 className="title">
                    {locale === "ar"
                      ? pageContent.introTitleAr
                      : pageContent.introTitleEn}
                  </h3>
                  <p>
                    {longText(
                      locale == "ar"
                        ? pageContent.introTopSectionTextEn
                        : pageContent.introTopSectionTextEn
                    )}
                  </p>
                </div>

                <p>
                  {longText(
                    locale == "ar"
                      ? pageContent.introBottomSectionTextEn
                      : pageContent.introBottomSectionTextEn
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-counter-area bg_cover pt-120 pb-120">
        <div className="container">
          <div className="about-counter-content">
            <div className="row">
              <div className="col-lg-4">
                <div className="top-Icon">
                  <i className="fas fa-file-signature" id="about-icon"></i>
                </div>
                <div className="box-title">
                  <span> {translate.academicModel} </span>
                </div>

                <p>{translate.academicModelContent}</p>
              </div>

              <div className="col-lg-4">
                <div className="top-Icon">
                  <i className="fas fa-bullseye" id="about-icon"></i>
                </div>
                <div className="box-title">
                  <span> {translate.targetGroup}</span>
                </div>
                <div className="text">
                  <p>{translate.targetGroupContent}</p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="top-Icon">
                  <i className="fas fa-paperclip" id="about-icon"></i>
                </div>
                <div className="box-title">
                  <span> {translate.coachPerformanceRecord} </span>
                </div>
                <div className="text">
                  <p>{translate.coachPerformanceRecordContent}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="core-features-area pt-110 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title section-title-2 text-center">
                <h4
                  className="title"
                  style={{ color: "rgba(13, 23, 48, 0.87)" }}
                >
                  {translate.askedQuestions}
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="faq-accordion mt-30">
                <div className="accordion" id="accordionExample">
                  {askedQuestions.map((questions: any, idx: number) => {
                    const title =
                      locale == "ar"
                        ? questions.questionTitleAr
                        : questions.questionTitleEn
                    const contentJeson =
                      locale == "ar"
                        ? questions.answerArabic?.json
                        : questions.answerEnglish?.json

                    const isSelectedItem = selectedItem === idx

                    return (
                      <CollapseItem
                        contentJeson={contentJeson}
                        index={idx}
                        title={title}
                        key={idx}
                        onSelect={(selIdx) => {
                          setSelectedItem(selIdx)
                        }}
                        isSelectedItem={isSelectedItem}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="testimonials-area pb-115 bg_cover"
        style={{
          backgroundImage: `url(assets/images/testimonials-pattern.png)`,
          direction: "ltr",
        }}
      >
        <div className="container">
          <div className="row testimonials-active">
            {pageContent.weSaidSection.map((e: any) => {
              const content = locale == "ar" ? e.contentAr : e.contentAr
              const theSaying = locale == "ar" ? e.theSayingAr : e.theSayingEn
              const theSayingJob =
                locale == "ar" ? e.theSayingJobAr : e.theSayingJobEn

              return (
                <div className="col-lg-12">
                  <div className="testimonials-content text-center">
                    <i className="fas fa-quote-left"></i>

                    <p>{longText(content)}</p>
                    <img
                      src="/images/nobody_m.original.jpg"
                      alt="testimonials"
                      width="50"
                      style={{ borderRadius: 20 }}
                    />
                    <h5>{theSaying}</h5>
                    <span>{theSayingJob}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <section
        className="sponsors-area bg_cover pb-120"
        style={{ direction: "ltr" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center  mb-40">
                <h3 className="title">{translate.ourSponsors}</h3>
              </div>
            </div>
          </div>
          <div className="row brand-active owl-carousel">
            {pageContent.ourSponsorsSection.map((sponsor: any) => {
              return (
                <div className="col-lg-12">
                  <div className="single-brand">
                    <img
                      src={sponsor.logo.file.url}
                      alt="brand"
                      className="single-brand-image"
                      width="280"
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <a
              className="main-btn"
              href="http://www.tt-academy.com/"
              id="visitSite"
            >
              <i className="fal fa-home"></i> {translate.goToSit}
            </a>
          </div>
        </div>
      </section>

      <Footer lang={locale} />
    </div>
  )
}

export default coachingAcademy
